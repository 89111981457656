import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import styles from './steps.module.scss';
import FormContainer from '../Form/form-container';
import FormField from '../Form/form-field';
import Button from '../Button/button';
import TextInput from '../Form/text-input';

export default function Address({ set, address }) {
  const intl = useIntl();
  const [_address, setAddress] = useState(address);
  const disabled = _address.length < 1;
  const nextStepPath = '/get-quote/people';

  return(
    <FormContainer>
      <h1>{intl.formatMessage({ id: "quoteHeadline" })}</h1>

      <p>{intl.formatMessage({ id: "quoteDisclaimerArea" })}</p>
      <p>{intl.formatMessage({ id: "quoteDisclaimerPrice" })}</p>

      <FormField
        label={intl.formatMessage({ id: "quoteAddressInputLabel" })}
        name={'address'}
      >
        <TextInput
          fieldId={"address"}
          handleChange={setAddress}
          value={_address}
          icon={'/images/location-pin.svg'}
          required
        />
      </FormField>


      <div className={styles.buttonContainer}>
        <Link to={nextStepPath} style={disabled ? {pointerEvents: 'none'} : {}}>
          <Button handleClick={() => set({address: _address})} disabled={disabled} elevated={false}>
            {intl.formatMessage({ id: "continueButtonLabel" })}
          </Button>
        </Link>
      </div>
    </FormContainer>
  );
}
