import React from 'react';
import QuoteLayout from '../../components/QuoteLayout/quote-layout';
import Address from '../../components/GetQuoteSteps/address';
import QuoteContext from '../../context/quote-context';
import FormContainer from '../../components/Form/form-container';

export default function QuoteAddressPage() {
  return (
    <QuoteLayout>
      <QuoteContext.Consumer>
        {quote => (
          <FormContainer>
            <Address address={quote.address} set={quote.set} />
          </FormContainer>
        )}
      </QuoteContext.Consumer>
    </QuoteLayout>
  );
}
